import React from "react"
import { Link } from "gatsby"

const Project = ({ data }) => {
  return (
    <section className="project">
      <Link to="/">&larr; Index</Link>

      <h2>{data.project_title.text}</h2>

      <div className="project__assets">
        {data.assets.map((asset, ind) => {
          return <Asset asset={asset} />
        })}
      </div>
    </section>
  )
}

const Asset = ({ asset }) => {
  const [narrowClass, setNarrowClass] = React.useState("")

  function imageOnly() {
    return asset.image.url && !asset.video.url
  }

  React.useEffect(() => {
    function checkNarrowClass() {
      if (!asset.image.url) return false

      if (
        asset.image.dimensions.width / asset.image.dimensions.height < 0.9 ||
        asset.image.dimensions.width < window.innerWidth * 0.5
      ) {
        setNarrowClass("narrow")
      }
    }

    checkNarrowClass()
  }, [])

  return (
    <div className={`project__asset ${narrowClass}`}>
      {imageOnly() && (
        <img
          src={asset.image.url}
          alt={asset.image.alt ? asset.image.alt : ""}
        />
      )}
      {asset.video.url && (
        <video
          src={asset.video.url}
          poster={asset.image.url ? asset.image.url : null}
          controls
        />
      )}
    </div>
  )
}

export default Project
