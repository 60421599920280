import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Info from "../components/Info"
import Project from "../components/Project"

export const ProjectTemplate = ({ data }) => {
  if (!data) return null
  const project = data.prismicProject.data

  return (
    <Layout>
      <Seo title={project.project_title.text} />

      <Info />
      <Project data={project} />
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($id: String) {
    prismicProject(id: { eq: $id }) {
      data {
        project_title {
          text
        }
        project_description {
          html
          text
        }
        assets {
          image {
            url(imgixParams: { maxW: 1600 })
            dimensions {
              width
              height
            }
          }
          video {
            url
          }
        }
      }
    }
  }
`

export default ProjectTemplate
